const pathMap = new Map();
pathMap.set(/^\/$/, "Главная");
pathMap.set(/\/exams/, "Осмотры");
pathMap.set(/\/patients/, "Обследуемые");
pathMap.set(/\/journal/, "Журнал");
pathMap.set(/\/history/, "История");
pathMap.set(/\/faq/, "Вопросы и ответы");
pathMap.set(/\/exams\/\d+/, "Осмотры");
pathMap.set(/\/notifications/, "Уведомления");
pathMap.set(/\/profile/, "Профиль пользователя");
pathMap.set(/\/users/, "Пользователи");
pathMap.set(/\/terminals/, "Терминалы");
pathMap.set(/\/organizations/, "Организации");
pathMap.set(/\/reports/, "Отчеты");

export function getTitleFromPath(key: string) {
  for (const [re, val] of pathMap.entries()) {
    const res = re.exec(key);

    if (res) {
      return val;
    }
  }
}

export function checkIsCurrentPath(path: string, keysList: string[]) {
  for (const [re] of pathMap.entries()) {
    const res = re.exec(path);

    if (res) {
      for (const key of keysList) {
        const res = re.exec(key);

        if (res) {
          return true;
        }
      }
    }
  }
  return false;
}
