export const FileChartIcon = ({ color }: { color?: "green" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.8913V5.33341C11.6667 5.80012 11.6667 6.03348 11.7575 6.21174C11.8374 6.36854 11.9649 6.49602 12.1217 6.57592C12.2999 6.66675 12.5333 6.66675 13 6.66675H16.4421M6.66668 12.5V15M13.3333 10.8334V15M10 8.75002V15M16.6667 8.32354V14.3334C16.6667 15.7335 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.7721 17.8212 15.3017 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33334C5.93321 18.3334 5.23315 18.3334 4.69837 18.0609C4.22796 17.8212 3.84551 17.4387 3.60583 16.9683C3.33334 16.4335 3.33334 15.7335 3.33334 14.3334V5.66669C3.33334 4.26656 3.33334 3.56649 3.60583 3.03171C3.84551 2.56131 4.22796 2.17885 4.69837 1.93917C5.23315 1.66669 5.93321 1.66669 7.33334 1.66669H10.0098C10.6213 1.66669 10.927 1.66669 11.2148 1.73576C11.4698 1.797 11.7137 1.89801 11.9374 2.03509C12.1897 2.18969 12.4059 2.40588 12.8382 2.83826L15.4951 5.49511C15.9275 5.92749 16.1437 6.14368 16.2983 6.39597C16.4353 6.61966 16.5364 6.86352 16.5976 7.11861C16.6667 7.40633 16.6667 7.71206 16.6667 8.32354Z"
      stroke={color === "green" ? "#67D394" : "#85879B"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
