import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Spin } from "antd";
import {
  authPersonState,
  authStatusState,
  setGuestStatus,
} from "../../storeSlices/login";
import { deleteCurrentToken } from "../../app/firebase";
import { useEffect } from "react";
import { usePutApiPersonsChangeNotificationTokenMutation } from "../../app/apiRTK";

const Logout = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(authStatusState);
  const authPerson = useAppSelector(authPersonState);

  // useMutation
  const [changePersonToken] = usePutApiPersonsChangeNotificationTokenMutation();

  const firstEntry = sessionStorage.getItem("firstEntry");

  useEffect(() => {
    deleteCurrentToken();

    if (authPerson && authPerson.notificationToken) {
      changePersonToken({ id: authPerson.id, notificationToken: undefined })
        .unwrap()
        .then(() => {
          dispatch(setGuestStatus());
        });
    } else {
      dispatch(setGuestStatus());
    }
  }, [authPerson, changePersonToken, dispatch]);

  if (firstEntry) {
    sessionStorage.removeItem("firstEntry");
  }

  if (status === "guest") {
    return <Navigate to="/login" />;
  } else {
    return <Spin size="large" />;
  }
};

export default Logout;
