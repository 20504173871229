import cn from "classnames";
import style from "./LeftSideBar.module.scss";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  BookOpenIcon,
  ChevronIcon,
  EngineeringIcon,
  FileIcon,
  GridIcon,
  LogoIcon,
  MedicalCrossIcon,
  UsersIcon,
} from "../../../../assets/icons";
import { checkIsCurrentPath } from "../../../../utils/pathMapFuncs";
import { useAppSelector } from "../../../../app/hooks";
import { authRoleState } from "../../../../storeSlices/login";
import { EnumRoles } from "../../../../app/types";
import { FileChartIcon } from "../../../../assets/icons/FileChart";

const LeftSideBar = () => {
  const [isMouseEnterSideBar, setIsMouseEnterSideBar] = useState(false);
  const [isShownAdmin, setShownAdmin] = useState(false);

  const authRole = useAppSelector(authRoleState);

  const location = useLocation();

  const navClassName = ({
    isActive,
    isPending,
  }: {
    isActive: boolean;
    isPending: boolean;
  }) => {
    return isPending ? style.pending : isActive ? style.active : "";
  };

  return (
    <div
      className={cn(style.leftSidebar, {
        [style.mouseEnter]: isMouseEnterSideBar,
      })}
      onMouseEnter={() => setIsMouseEnterSideBar(true)}
      onMouseLeave={() => setIsMouseEnterSideBar(false)}
    >
      <div className={style.logo}>
        <div className={style.wrapperIcon}>
          <LogoIcon />
          {/* <div className={style.icl}>
            <IclWorkShiftIcon />
          </div> */}
        </div>
      </div>
      <div className={style.menu}>
        <ul>
          <li>
            <NavLink
              to="."
              className={navClassName}
              end
              state={{ pageTitle: "Главная" }}
            >
              {({ isActive }) => {
                return (
                  <>
                    <GridIcon color={isActive ? "green" : undefined} />{" "}
                    <span className={cn({ [style.greenSpan]: isActive })}>
                      Главная
                    </span>
                  </>
                );
              }}
            </NavLink>
          </li>

          <li>
            <NavLink
              to="exams"
              className={navClassName}
              end
              state={{ pageTitle: "Осмотры" }}
            >
              {({ isActive }) => {
                return (
                  <>
                    <MedicalCrossIcon color={isActive ? "green" : undefined} />{" "}
                    <span className={cn({ [style.greenSpan]: isActive })}>
                      Осмотры
                    </span>
                  </>
                );
              }}
            </NavLink>
          </li>

          <li>
            <NavLink
              to="patients"
              className={navClassName}
              end
              state={{ pageTitle: "Обследуемые" }}
            >
              {({ isActive }) => {
                return (
                  <>
                    <UsersIcon color={isActive ? "green" : undefined} />{" "}
                    <span className={cn({ [style.greenSpan]: isActive })}>
                      Обследуемые
                    </span>
                  </>
                );
              }}
            </NavLink>
          </li>

          <li>
            <NavLink
              to="journal"
              className={navClassName}
              end
              state={{ pageTitle: "Журнал" }}
            >
              {({ isActive }) => {
                return (
                  <>
                    <BookOpenIcon color={isActive ? "green" : undefined} />{" "}
                    <span className={cn({ [style.greenSpan]: isActive })}>
                      Журнал
                    </span>
                  </>
                );
              }}
            </NavLink>
          </li>

          <li>
            <NavLink
              to="history"
              className={navClassName}
              end
              state={{ pageTitle: "История" }}
            >
              {({ isActive }) => {
                return (
                  <>
                    <FileIcon color={isActive ? "green" : undefined} />{" "}
                    <span className={cn({ [style.greenSpan]: isActive })}>
                      История
                    </span>
                  </>
                );
              }}
            </NavLink>
          </li>

          {authRole?.includes(EnumRoles.Руководитель) && (
            <li>
              <NavLink
                to="reports"
                className={navClassName}
                end
                state={{ pageTitle: "Отчеты" }}
              >
                {({ isActive }) => {
                  return (
                    <>
                      <FileChartIcon color={isActive ? "green" : undefined} />{" "}
                      <span className={cn({ [style.greenSpan]: isActive })}>
                        Отчеты
                      </span>
                    </>
                  );
                }}
              </NavLink>
            </li>
          )}

          {(authRole?.includes(EnumRoles.Руководитель) ||
            authRole?.includes(EnumRoles.Админ)) && (
            <li className={style.subMenu}>
              <div
                className={cn(style.toggleMenuLabel, {
                  [style.menuIsShown]: isShownAdmin,
                  [style.subItemIsSelected]: checkIsCurrentPath(
                    location.pathname,
                    ["/users", "/terminals", "/organizations"]
                  ),
                })}
                onClick={() => setShownAdmin((prevState) => !prevState)}
              >
                <EngineeringIcon /> <span>Администрирование</span>{" "}
                <ChevronIcon className={style.chevron} />
              </div>

              <ul
                className={cn(style.menuItems, {
                  [style.shown]: isShownAdmin,
                })}
              >
                <li>
                  <NavLink
                    to="users"
                    className={navClassName}
                    end
                    // state={{ pageTitle: "Вопросы и ответы" }}
                  >
                    {({ isActive }) => {
                      return (
                        <>
                          <span className={cn({ [style.greenSpan]: isActive })}>
                            Пользователи
                          </span>
                        </>
                      );
                    }}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="terminals"
                    className={navClassName}
                    end
                    // state={{ pageTitle: "Вопросы и ответы" }}
                  >
                    {({ isActive }) => {
                      return (
                        <>
                          <span className={cn({ [style.greenSpan]: isActive })}>
                            Терминалы
                          </span>
                        </>
                      );
                    }}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="organizations"
                    className={navClassName}
                    end
                    // state={{ pageTitle: "Вопросы и ответы" }}
                  >
                    {({ isActive }) => {
                      return (
                        <>
                          <span className={cn({ [style.greenSpan]: isActive })}>
                            Организации
                          </span>
                        </>
                      );
                    }}
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeftSideBar;
