import { Link } from "react-router-dom";
import style from "./NotificationPopup.module.scss";
import { INotification } from "../../../../app/types";
import dayjs from "dayjs";
import { getNotificationText } from "../../../../utils/getNotificationText";
import AntPopConfirm from "../../../../components/AntPopConfirm";

type PopupType = {
  top?: number;
  left?: number;
  isOpen?: boolean;
  setIsNotificationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: INotification[];
  readAllMessages: () => void;
};

const NotificationPopup = (props: PopupType) => {
  const { setIsNotificationPopup, notifications = [], readAllMessages } = props;
  return (
    <div className={style.popup}>
      <div className={style.header}>
        <h3>
          {getNotificationText(notifications?.length)}{" "}
          {notifications?.length > 0 && (
            <AntPopConfirm
              title="Отметить все уведомления как прочитанные"
              description={
                "Вы уверены, что хотите отметить все уведомления как прочитанные?\nЭто действие нельзя будет отменить."
              }
              onConfirm={readAllMessages}
              okText="Прочитать"
              cancelText="Отменить"
            >
              <span className={style.link}>Прочитать все</span>
            </AntPopConfirm>
          )}
        </h3>
      </div>
      <div className={style.content}>
        {notifications.length > 0 &&
          notifications
            .sort((a, b) => {
              return dayjs(a.time).isBefore(dayjs(b.time)) ? 1 : -1;
            })
            .slice(0, 5)
            .map((nf, i) => {
              return (
                <div className={style.notification} key={i}>
                  <div className={style.title}>{nf.title}</div>
                  <div className={style.text}>{nf.description}</div>
                  <Link
                    to={`/exams/${nf.examId}`}
                    onClick={() => setIsNotificationPopup(false)}
                    className={style.link}
                  >
                    Перейти
                  </Link>

                  <div className={style.date}>{nf.time}</div>
                </div>
              );
            })}
      </div>
      <div className={style.footer}>
        <div className={style.allNotification}>
          <Link
            to="notifications"
            onClick={() => setIsNotificationPopup(false)}
            className={style.link}
          >
            Все уведомления
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
